.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.album-image {
  max-width: 900px;
  width: 75%;
  margin-bottom: 15px;
}

.about-image {
  max-width: 100%;
  margin-bottom: 15px;
}

.about-container {
  display: inline-block;
  max-width: 1410px;
}

.about-image-wrapped {
  max-width: 40%;
  margin-right: 20px;
}

button:focus{
  outline: none
}

.header {
  max-width: 35%;
  display: inline-block;
}

.header-img {
  max-width: 100%;
  margin-bottom: 10px;
}

.App-link {
  color: #61dafb;
}

.custom-container {
  margin: 0 10%;
}

.footer-container {
  display: block;
  margin-top: 40px;
  margin-bottom: 15px;
}

.ig-logo {
  max-width: 5%;
  background: #00000096;
  border-radius: 30%;
}

.menu-button{
  background: #ca82bc85;
  color: #fff;
  border: none;
  position: relative;
  height: 40px;
  font-size: 1.0em;
  padding: 0 1em;
  cursor: pointer;
  transition: 800ms ease all;
  outline: none;
  border-radius: 10px;
  margin-right: 5px;
  border: 1px solid #ffffff30;
}
.menu-button:hover{
  background:#fff;
  color:#ae4896;
}
.menu-button-selected {
  background:#fff;
  color:#ae4896;
  border: none;
  position: relative;
  height: 40px;
  font-size: 1.0em;
  padding: 0 1em;
  cursor: pointer;
  transition: 800ms ease all;
  outline: none;
  border-radius: 10px;
  margin-right: 5px;
  border: 1px solid #ffffff30;
}
.menu-button-selected::before {
  width:83%;
  transition:800ms ease all;
  content:'';
  position:absolute;
  top:0;
  right:8px;
  height:2px;
  /* width:0; */
  background: #ca82bc85;
  /* transition:400ms ease all; */
}

.menu-button-selected::after {
  width:83%;
  transition:800ms ease all;
  content: '';
  position: absolute;
  top: 36px;
  left: 8px;
  height: 2px;
  background: #ca82bc85;
}

.menu-button:before,.menu-button:after{
  content:'';
  position:absolute;
  top:0;
  right:8px;
  height:2px;
  width:0;
  background: #ca82bc85;
  transition:400ms ease all;
}
.menu-button:after{
  right:inherit;
  top:inherit;
  left:8px;
  bottom:0;
}
.menu-button:hover:before,.menu-button:hover:after{
  width:83%;
  transition:800ms ease all;
}

.menu-buttons-group {
  justify-content: space-evenly;
  display: flex;
  padding: 0 20%;
  margin: 10px 0 35px;
}

.modal-content {
  background-color: #423f4b;
  color: antiquewhite;
  text-align: center;
}

.modal-header {
  border: none;
}

.close {
  color: antiquewhite; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: bullshit;
  src: url('bullshit.ttf');
}

/* body {
  background-image: url('no_grill.jpg');
  background-position: center;
  background-size: 55%;
  height: 100%;
} */

.background {
  background: rgba(48, 65, 103, 0.8);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: scroll;
  text-align: center;
}

h1 {
  color: antiquewhite;
  font-family: sans-serif;
  letter-spacing: 3.5px;
  font-weight: 300;
  background-color: transparent;
  margin-bottom: 12px;
  font-size: 1.8em;
}

p {
  margin: 0;
}

a {
  text-decoration: none !important;
  color: antiquewhite !important;
}

a:hover {
  color: #7e9fb8 !important;
}

textarea {
  text-transform: uppercase; 
}
textarea::placeholder {
  color: rgb(158, 158, 158);
  font-size: 1.2em;
  font-family: bullshit;
}

textarea:focus {
  outline: none;
}
textarea {
  font-family: bullshit;
  font-size: 1.2em;
}

.text-container {
  margin-top: 20px;
}

.music-links-container {
  margin: 20px 0px;
}

.music-links-container img {
  max-width: 60px;
  margin-right: 12px;
}

.download-img {
  max-width: 40px;
}

.show {
  padding: 0 20%;
}

.show-img {
  max-width: 100%;
  margin-bottom: 10px;
}

.show-text {
  margin-bottom: 10px;
  border-bottom: 1px solid;
}

.soundcloud-icon {
  max-width: 63px !important;
  margin-bottom: -2px;
}

.subtitle-container {
  font-size: 2em;
  font-family: bullshit;
  color: antiquewhite;
  letter-spacing: 2px;
}

.subtext {
  font-size: 1em;
  margin-top: 10px;
  font-weight: 400;
  font-style: italic;
  color: #adadad;
}

.title-text, .about-text {
  color: antiquewhite;
  font-family: sans-serif;
  letter-spacing: 3.5px;
  font-weight: 300;
  background-color: transparent;
  margin-bottom: 12px;
  font-size: 1.2em
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.about-text {
  text-align: justify;
  /* max-width: 90%; */
  margin: auto;
  font-weight: 400;
}

#message {
  background-color: rgba(106, 116, 184, 0.788235294117647);
  color: white;
  border-radius: 10px;
  border: none;
  padding: 10px;
}

.post-it-text {
  color: antiquewhite;
  font-family: sans-serif;
  margin: 10px 0;
}

iframe .g-background-default {
  background-color: #000000;
}

a.sc-link-dark:hover, a.sc-link-medium:hover {
  color: #8255b3;
}

a.sc-link-dark {
  color: white;
}

.sc-background-white {
  background-color: #868686;
  color: white;
}

.youtube-icon {
  max-width: 65px !important;
  margin-bottom: 4px;
}

@media screen and (max-width: 480px){
  /* body {
    background-image: url('no_grill.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 261%;
  } */

  .album-image {
    width: 90%;
    margin-bottom: 15px;
  }

  .header {
    max-width: 100%;
  }
  
  .header-img {
    max-width: 73%;
  }

  iframe.youtube {
    width: 100% ;
    height: 200px ;
  }

  iframe.soundcloud {
    height: 620px;
  }

  .ig-logo {
    max-width: 12%;
  }

  .menu-buttons-group {
    padding: 0 10%;
  }

  .show {
    padding: 0 8%;
  }

  .soundcloud-icon {
    max-width: 12% !important;
  }

  .youtube-icon {
    max-width: 13% !important;
    margin-right: 0 !important;
    margin-bottom: 4px !important;
  }

  .custom-container {
    margin: 0 10px;
  }
  
  .music-links-container img {
    max-width: 11%;
  }
  .download-img {
    max-width: 15%;
  }

  .video-list {
    display: block;
  }
}
